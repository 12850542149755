// PageOneBody.js
import styled from 'styled-components';

const GamePageCss = styled.body`
  background: #f5f6fa;
  padding-top:70px;
  /* other styles for page one */
`;

export default GamePageCss;
